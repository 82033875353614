@import 'fonts';
@import 'colors';

:root {
  --mouse-x: 0;
  --mouse-y: 0;
	--gradient-shadow: linear-gradient(
		45deg,
		red,
		orange,
	);
}

* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: 'Bicyclette-Regular';
  font-size: 24px;
  color: $gray;
}
#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}