@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-header {
  background: #fff;
  border-radius: 0 0 20px 20px;
  &.black {
    background: $black;
    border-radius: 0;
    & .commu-header__wrapper__menu {
      border-top-color: #000;
      li {
        border-top-color: #000;
        a {
          color: #fff;
        }
      }
      li:hover {
        border-top-color: #fff;
      }
    }
  }
  &__wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: center;
    @include phone {
      display: none;
    }
    &__menu {
      text-align: right;
      ul {
        list-style: none;
        li {
          display: inline-block;
          padding: 24px;
          border-top: 5px solid #fff;
          a {
            font-family: 'Bicyclette-Regular';
            font-size: 13px;
            line-height: 18.48px;
            text-decoration: none;
            text-transform: uppercase;
            color: inherit;
          }
        }
        li:hover {
          border-top-width: 5px;
          border-top-style: solid;
        }
        li:first-child {
          padding-left: 10px;
          padding-right: 10px;
        }
        li:last-child {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      @include phone {
        display: none;
      }
      &._D06852 {
        ul {
          li.active,
          li:hover {
            border-top-color: #D06852;
          }
        }
      }
      &._4E61A6 {
        ul {
          li.active,
          li:hover {
            border-top-color: #4E61A6;
          }
        }
      }
      &._9597C8 {
        ul {
          li.active,
          li:hover {
            border-top-color: #9597C8;
          }
        }
      }
      &._D5D250 {
        ul {
          li.active,
          li:hover {
            border-top-color: #D5D250;
          }
        }
      }
      &._070809 {
        ul {
          li.active,
          li:hover {
            border-top-color: #070809;
          }
        }
      }
    }

    @include phone {
      grid-template-columns: 1fr;
    }
  }
  &__mobile {
    display: none;
    @include phone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 28px 20px 0;
    }
    &__panel {
      display: none;
      @include phone {
        width: 100%;
        height: 100vh;
        display: block;
        background-color: $black;
        position: fixed;
        top: 0;
        z-index: 999;
      }
      ul {
        @include phone {
          text-align: right;
          padding: 100px 50px 50px 50px;
          list-style: none;
          margin: 0;
        }
        li {
          @include phone {
            margin: 23.5px 0;
          }
          a {
            @include phone {
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
      &__close {
        img {
          @include phone {
            position: absolute;
            top: 50px;
            right: 50px;
          }
        }
      }
    }
  }
}