@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-button-default {
  margin: 0 auto;
  width: 100%;
  padding: 10px 65px;
  background: $black;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Bicyclette-Bold';
  font-size: 24px;
  line-height: 28.56px;
  cursor: pointer;
  @include phone {
    max-width: 100% !important;
  }
}