@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-main-talentos {
  width: 100%;
  background: #fff;
  &__quesomos {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 440px 1fr;
    grid-gap: 50px;
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      @include phone {
        font-size: 18px;
        line-height: 22px;
        padding: 0;
        margin-top: 5px;
        border: none;
      }
    }
    p {
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 1.2;
    }
    &__col {
      @include phone {
        padding: 0 20px;
      }
    }
    div:nth-child(2) {
      position: relative;
      img:first-child {
        position: absolute;
        top: 60px;
        right: 30px;
        width: 130px;
        height: 130px;
        animation: rotate 15s linear infinite;
        z-index: 5;
        @include phone {
          display: none;
        }
      }
      @include phone {
        padding: 0 20px !important;
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    @include phone {
      width: 100%;
      max-width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
  &__quehacemos {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    @include phone {
      max-width: 100%;
      padding: 0 20px;
    }
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      @include phone {
        font-size: 18px;
        line-height: 22px;
        padding: 0;
        margin-top: 5px;
        border: none;
      }
    }
    p {
      font-size: 20px;
      line-height: 1.2;
      @include phone {
        font-size: 14px;
        line-height: 16.17px;
      }
    }
    &__estrategias {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      @include phone {
        margin-top: 40px;
        padding: 0px 20px;
        overflow-y: scroll;
        // grid-template-columns: 1fr;
      }

      &__item {
        text-align: center;
        transition: all .3s ease-in-out;
        img {
          width: auto;
          height: 210px;
          transition: .3s ease-in-out;
          animation: float 3s ease-in-out infinite;
        }
        // img:hover {
        //   transition: .3s ease-in-out;
        //   animation: none;
        //   transform: scale(1.07) rotate(-5deg);
        // }
        h2 {
          margin: 2px 0;
          font-size: 18px;
          line-height: 1.2;
          font-family: 'Bicyclette-Bold';
          color: $black;
        }
        p {
          font-size: 13px;
          line-height: 1.2;
          text-align: left;
        }
      }
      &__item:nth-child(2) {
        img {
          transition: .4s ease-in-out;
          animation: float 4s ease-in-out infinite;
        }
      }
      &__item:nth-child(3) {
        img {
          transition: .3s ease-in-out;
          animation: float 5s ease-in-out infinite;
        }
      }
      &__item:nth-child(4) {
        img {
          transition: .3s ease-in-out;
          animation: float 6s ease-in-out infinite;
        }
      }
    }
  }
  &__servicios {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    @include phone {
      max-width: 100%;
      padding: 0 20px;
    }
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      @include phone {
        font-size: 18px;
        line-height: 22px;
        padding: 0;
        margin-top: 5px;
        border: none;
      }
    }
    p {
      font-size: 15px;
      line-height: 1.2;
    }
    &__items {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      @include phone {
        padding: 10px 20px;
        overflow-y: scroll;
        grid-gap: 30px;
      }
      &__item {
        text-align: center;
        @include phone {
          width: 100%;
        }
        img {
          width: 100%;
          transition: .2s ease-in-out;
          @include phone {
            width: 200px;
          }
        }
        img:hover {
          transition: .2s ease-in-out;
          transform: scale(1.1);
        }
        h3 {
          margin-top: 5px;
          font-family: 'Bicyclette-Bold';
          color: $black;
          font-size: 15px;
          line-height: 1.2;
        }
      }
    }
  }
  &__quenosomos {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    @include phone {
      max-width: 100%;
      padding: 0 20px;
    }
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      @include phone {
        font-size: 18px;
        line-height: 22px;
        padding: 0;
        margin-top: 5px;
        border: none;
      }
    }
    p {
      font-size: 15px;
      line-height: 1.2;
    }
    &__grid {
      margin-top: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 110px);
      @include phone {
        display: none;
      }
      & > div {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        box-sizing: border-box;
        // border: 1px solid #D5D5D5;
        font-family: 'Bicyclette-Black';
        color: $black;
        @include phone {
          display: none;
        }
      }
      &__cover {
        overflow: hidden;
        width: 100%;
        height: 106px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        img {
          height: auto;
          width: 100%;
        }
        img:hover {
          animation: float 2s infinite;
        }
      }
    }
    &__gridmobile {
      margin-top: 40px;
      display: none;
      @include phone {
        display: block;
        width: 100%;
      }
      img {
        width: 100%;
      }
      h1 {
        display: none;
        font-size: 24px;
        line-height: 29.33px;
        text-align: center;
        border: none;
        margin: 0;
        padding: 0;
        @include phone {
          margin-top: 20px;
          display: block;
        }
      }
    }
  }
  &__contacto {
    max-width: 1000px;
    margin: 0 auto;
    @include phone {
      max-width: 100%;
      padding: 0 20px;
    }
    &__wrapper {
      padding-top: 20px;
      text-align: center;
      @include phone {
        padding-top: 60px;
        position: relative;
      }
      img {
        @include phone {
          width: 100%;
        }
      }
      h2 {
        font-family: 'Bicyclette-Bold';
        font-size: 50px;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 0 0 $black, -1px 0 0 $black, 0 1px 0 $black, 0 -1px 0 $black, 1px 1px $black, -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black;
      }
      ul {
        margin: 15px 0;
        list-style: none;
        li {
          padding: 0 14px;
          display: inline-block;
          a {
            img {
              height: 23px;
            }
          }
        }
      }
      p {
        a {
          text-decoration: none;
          color: $black;
          font-size: 20px;
          line-height: 1.2;
        }
        margin: 16px 0;
      }
    }
    &__onfire {
      width: 250px;
      margin: 40px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      p {
        opacity: 0.35;
        color: $black;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
      }
      img {
        opacity: 0.35;
        margin-left: 10px;
        height: 30px;
        transition: opacity .2s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    &__form {
      background: $black;
      height: calc(100vh - 198px);
      // overflow: hidden;
      @include phone {
        height: 100%;
        overflow: auto;
      }
      &__wrapper {
        max-width: 1000px;
        margin: 0 auto;
        @include phone {
          max-width: 100%;
          padding: 0 20px;
        }
        &__back {
          color: #fff;
          cursor: pointer;
          font-size: 18px;
          margin-bottom: 10px;
          @include phone {
            margin-top: 16px;
            margin-bottom: 0px;
          }
          img {
            height: 12px;
            margin-right: 10px;
          }
        }
        &__container {
          display: grid;
          grid-template-columns: 60% 40%;
          @include phone {
            grid-template-columns: 1fr;
          }
          &__thumb {
            width: 100%;
            display: flex;
            justify-content: center;
            img {
              height: 510px;
              width: auto;
            }
            @include phone {
              display: none;
            }
          }
          &__formarea {
            padding: 0;
            @include phone {
              padding: 40px 0 50px;
            }
            form {
              h2 {
                font-family: 'Bicyclette-Black';
                color: #fff;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 1;
                margin: 10px 0;
              }
              label {
                display: flex;
                align-items: center;
                position: relative;
                font-family: 'Bicyclette-Regular';
                font-size: 12px;
                line-height: 1;
                width: 100%;
                max-width: 100%;
                height: 32px;
                border: 1px solid #fff;
                border-radius: 10px;
                color: #fff;
                text-transform: uppercase;
                margin-bottom: 12px;
                padding-left: 17px;
                box-sizing: border-box;
                input[type="file"] {
                  border: none;
                  position: absolute;
                  border-radius: 0;
                  width: 100px;
                  top: 5px;
                  right: 10px;
                  color: #000;
                }
              }
              input {
                font-family: 'Bicyclette-Regular';
                font-size: 12px;
                line-height: 1;
                width: 100%;
                max-width: 100%;
                background: transparent;
                height: 32px;
                border: 1px solid #fff;
                border-radius: 10px;
                color: #fff;
                text-transform: uppercase;
                margin-bottom: 12px;
                padding-left: 17px;
                box-sizing: border-box;
              }
              input::placeholder {
                color: #fff;
              }
              input:hover,
              input:focus {
                box-shadow: none;
                outline: none;
              }
              button {
                width: 100%;
                background: #fff;
                border-radius: 10px;
                text-transform: uppercase;
                font-family: 'Bicyclette-Bold';
                font-size: 22px;
                line-height: 1.2;
                color: $black;
                padding: 5px 0;
                border: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.img-full {
  width: 100%;
}
.img-full-height {
  height: 100%;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

@keyframes float {
  0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-15px);
	}
	100% {
		transform: translatey(0px);
	}
}
