@import 'colors';

@mixin fonts {
  font-display: swap;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Bicyclette-Black';
  src: url('../assets/fonts/Bicyclette-Black.ttf') format('truetype');
  @include fonts;
}

@font-face {
  font-family: 'Bicyclette-Bold';
  src: url('../assets/fonts/Bicyclette-Bold.ttf') format('truetype');
  @include fonts;
}

@font-face {
  font-family: 'Bicyclette-Regular';
  src: url('../assets/fonts/Bicyclette-Regular.ttf') format('truetype');
  @include fonts;
}

@font-face {
  font-family: 'Bicyclette-Light';
  src: url('../assets/fonts/Bicyclette-Light.ttf') format('truetype');
  @include fonts;
}
