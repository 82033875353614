@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-main-marcas {
  &__quesomos {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 180px;
    @include phone {
      gap: 0;
      grid-template-columns: 1fr;
    }
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      @include phone {
        font-size: 18px;
        margin: 0;
        border: none;
      }
    }
    p {
      font-size: 15px;
      line-height: 1.2;
    }
    &__col {
      @include phone {
        padding: 0 20px;
      }
    }
    div:nth-child(2) {
      @include phone {
        padding-top: 20px !important;
        text-align: center;
      }
      img {
        height: 505px;
        transition: all .3s ease-in-out;
        @include phone {
          text-align: center;
          margin: 0 auto;
          width: 50%;
          height: auto;
        }
      }
      img:hover {
        transition: all .3s ease-in-out;;
        transform: perspective(2000px) rotateX(0deg) rotateY(-30deg) rotateZ(0deg) translateX(30px) translateY(0px) translateZ(0px);
      }
    }
  }
  &__quehacemos {
    max-width: 1000px;
    margin: 0 auto;
    @include phone {
      padding: 0 20px;
    }
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      margin-right: 50%;
      @include phone {
        font-size: 18px;
        margin: 0;
        border: none;
      }
    }
    p {
      font-size: 15px;
      line-height: 1;
    }
    &__thumbnail {
      position: relative;
      max-width: 590px;
      margin-left: 20%;
      margin-top: -10px;
      z-index: 3;
      @include phone {
        margin-left: 0;
        margin-top: 25px;
        margin-bottom: 25px;
      }
      img {
        width: 100%;
        @include phone {
          margin: 0;
          max-width: 100%;
        }
      }
    }
    &__logo {
      position: absolute;
      top: -60px;
      left: 60%;
      width: 180px;
      height: auto;
      z-index: -1;
      transition: .2s;
      @include phone {
        width: 80px;
      }
      img {
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: .2s;
        transform: translateY(20px);
      }
      img:hover {
        transition: .2s;
        transform: translateY(0);
      }
    }
  }
  &__servicios {
    max-width: 1000px;
    margin: 0 auto;
    @include phone {
      padding: 0 20px;
    }
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      margin-right: 55%;
      @include phone {
        font-size: 18px;
        margin: 0;
        border: none;
      }
    }
    p {
      font-size: 15px;
      line-height: 1.2;
    }
    &__contenidos {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 5px;
      @include phone {
        max-width: 100%;
        overflow-y: scroll;
      }
      &__item {
        text-align: center;
        @include phone {
          width: 250px;
          margin: 25px 0;
        }
        &__cover {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            transition: all .3s ease-in-out;
          }
          img:hover {
            transition: all .3s ease-in-out;
            transform: scale(1.06) rotate(-2deg);
          }
        }
        h3 {
          font-family: 'Bicyclette-Bold';
          font-size: 20px;
          line-height: 1.2;
          color: $black;
          margin-bottom: 5px;
        }
        p {
          font-family: 'Bicyclette-Regular';
          font-size: 13px;
          line-height: 1.1;
        }
      }
    }
  }
  &__talentos {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;
    @include phone {
      padding: 0 20px;
    }
    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: -20px;
      @include phone {
        flex-direction: column;
        margin-top: -4px;
        margin-bottom: 10px;
      }
      h1 {
        padding: 10px 0;
        font-family: 'Bicyclette-Black';
        font-size: 28px;
        line-height: 1.2;
        color: $black;
        text-transform: uppercase;
        @include phone {
          margin-top: 0px;
          font-size: 22px;
        }
      }
      input {
        font-family: 'Bicyclette-Regular';
        font-size: 18px;
        width: 350px;
        max-width: 100%;
        padding: 7px 10px;
        margin-left: auto;
        margin-right: 50px;
        border-radius: 8px;
        border: 1px solid #070809;
        @include phone {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    &__contentload {
      width: 100%;
      min-height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      width: 100%;
      height: 400px;
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      position: relative;
      @include phone {
        height: auto;
        grid-template-columns: 40px 1fr 40px;
      }
      &__fullname {
        position: absolute;
        bottom: -3px;
        left: -10px;
        margin-bottom: 0;
        color: #fff;
        font-size: 52px;
        font-weight: bold;
        text-transform: uppercase;
        text-shadow: 2px 2px #000;
        span {
          color: #070809;
          text-shadow: 2px 2px #fff;
        }
        @include phone {
          display: none;
        }
      }
      .button-left, .button-right {
        align-self: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid #000;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        cursor: pointer;
        img {
          width: 6px;
        }
      }
      &__profile {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 300px;
        @include phone {
          grid-template-columns: 100%;
        }
        &__cover {
          width: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          @include phone {
            margin-bottom: -5px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0px;
          }
          img {
            width: 100%;
            @include phone {
              height: auto;
              width: 100%;
            }
          }
        }
        &__info {
          padding: 25px;
          background: #000;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          color: #fff;
          @include phone {
            border-top-right-radius: 0px;
            border-bottom-left-radius: 10px;
          }
          &_mobile_name {
            display: none;
            @include phone {
              display: block;
              margin-bottom: 10px;
              font-size: 15px;
            }
          }
          &_job {
            margin-bottom: 20px;
            opacity: 0.5;
            font-size: 13px;
          }
          &_description {
            font-size: 12px;
          }
          &_links {
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            img {
              height: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    &__slider {
      width: 100%;
      margin-bottom: 10px;
      overflow: hidden;
      display: flex;
      padding-top: 10px;
      padding-left: 4px;
      @include phone {
        display: none;
      }
      &__thumbnails {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-flow: column;
        gap: 20px;
        transition: all .2s;
        & > div {
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          .border {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 10px;
            top: -4px;
            left: -4px;
          }
          &.active .border {
            border: 1px solid #000;
          }
          img {
            width: 100%;
            border-radius: 10px;
          }
        }
      }
    }
    &__steps {
      width: 100%;
      display: flex;
      justify-content: center;
      @include phone {
        display: none;
      }
      span {
        cursor: pointer;
        font-size: 16px;
        padding-right: 5px;
        padding-left: 5px;
        &.active {
          color: #000
        }
      }
    }
  }
  &__quenosomos {
    max-width: 1000px;
    margin: 0 auto;
    @include phone {
      padding: 0 20px;
    }
    h1 {
      padding: 15px 0;
      margin-bottom: 20px;
      font-family: 'Bicyclette-Black';
      font-size: 28px;
      line-height: 1.2;
      color: $black;
      border-bottom: 1px solid $black;
      margin-right: 55%;
      @include phone {
        font-size: 18px;
        margin: 0;
        border: none;
      }
    }
    p {
      font-size: 15px;
      line-height: 1.2;
    }
    &__thumbnail {
      position: relative;
      max-width: 500px;
      margin-top: 10px;
      margin-left: 300px;
      @include phone {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
      }
      img:first-child {
        width: 100%;
        transition: .2s;
        @include phone {
          margin: 0;
          width: 100%;
        }
      }
      img:first-child:hover {
        transition: .2s;
        transform: scale(1.05) translate(10px, -10px);
      }
      img:nth-child(2) {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
    // img {
    //   position: relative;
    //   max-width: 500px;
    //   margin-top: -40px;
    //   margin-left: 300px;
    //   @include phone {
    //     width: 100%;
    //     margin: 0;
    //   }
    // }
  }
  &__contacto {
    max-width: 1000px;
    margin: 0 auto;
    &__wrapper {
      padding-top: 20px;
      text-align: center;
      @include phone {
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
      }
      h2 {
        font-family: 'Bicyclette-Bold';
        font-size: 50px;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 0 0 $black, -1px 0 0 $black, 0 1px 0 $black, 0 -1px 0 $black, 1px 1px $black, -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black;
      }
      ul {
        margin: 15px 0;
        list-style: none;
        li {
          padding: 0 14px;
          display: inline-block;
          a {
            img {
              height: 23px;
            }
          }
        }
      }
      p {
        a {
          text-decoration: none;
          color: $black;
          font-size: 20px;
          line-height: 1.2;
        }
        margin: 16px 0;
      }
      img {
        @include phone {
          width: 100%;
        }
      }
    }
    &__onfire {
      width: 250px;
      margin: 40px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      p {
        color: $black;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
        opacity: 0.35;
      }
      img {
        margin-left: 10px;
        height: 30px;
        opacity: 0.35;
        transition: opacity .2s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    &__form {
      background: $black;
      height: calc(100vh - 198px);
      // overflow: hidden;
      @include phone {
        padding: 0 20px;
      }
      &__wrapper {
        max-width: 1000px;
        margin: 0 auto;
        &__container {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          @include phone {
            grid-template-columns: 1fr;
          }
          &__formarea {
            padding: 0;
            @include phone {
              padding: 0;
            }
            form {
              margin-top: -20px;
              @include phone {
                margin-top: 10px;
              }
              p {
                color: #fff;
                cursor: pointer;
                font-size: 18px;
                margin-bottom: 10px;
                @include phone {
                  margin-top: 16px;
                  margin-bottom: 50px;
                }
                img {
                  height: 10px;
                  margin-right: 10px;
                }
              }
              h2 {
                font-family: 'Bicyclette-Black';
                color: #fff;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 1;
                margin-bottom: 5px;
              }
              input {
                font-family: 'Bicyclette-Regular';
                font-size: 12px;
                line-height: 1;
                width: 100%;
                max-width: 100%;
                background: transparent;
                height: 32px;
                border: 1px solid #fff;
                border-radius: 10px;
                color: #fff;
                text-transform: uppercase;
                margin-bottom: 12px;
                padding-left: 17px;
                box-sizing: border-box;
              }
              input::placeholder {
                color: #fff;
              }
              input:hover,
              input:focus {
                box-shadow: none;
                outline: none;
              }
              button {
                width: 100%;
                background: #fff;
                border-radius: 10px;
                text-transform: uppercase;
                font-family: 'Bicyclette-Bold';
                font-size: 22px;
                line-height: 1.2;
                color: $black;
                padding: 5px 0;
                border: none;
                cursor: pointer;
              }
            }
          }
          &__thumb {
            width: 100%;
            display: flex;
            justify-content: center;
            img {
              height: 510px;
              width: auto;
            }
            @include phone {
              display: none;
            }
          }
        }
      }
    }
  }
}

@keyframes float {
  0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-7px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes hueBg {
  0% {
		background-position: 0 0;
	}
	50% {
		background-position: 300% 0;
	}
	100% {
		background-position: 0 0;
	}
}