@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-main-intro {
  width: 100%;
  overflow: hidden;
  position: relative;
  @include phone{
    height: 100vh;
  }
  &__header {
    max-width: 1050px;
    margin: 0 auto;
    padding: 16px 5px;
    display: grid;
    grid-template-columns: 1fr 200px;
    box-sizing: border-box;
    @include phone {
      text-align: center;
      display: flex;
      flex-direction: column;
      z-index: 2;
      padding: 0px;
    }
    a {
      text-decoration: none;
      animation: button-in 1s;
    }
    img {
      width: 160px;
      margin-top: 10px;
      margin-left: 16px;
      animation: logo-in 1s;
      @include phone {
        display: none;
      }
    }
  }
  &__wrapper {
    width: 100%;
    padding-top: 18px;
    max-width: 1000px;
    margin: 0 auto -70px;
    display: grid;
    grid-template-columns: 1fr 455px 1fr;
    gap: 30px;
    justify-content: center;
    align-items: flex-start;
    @include phone {
      grid-template-columns: 1fr;
      height: 100%;
      height: 100vh;
      padding-top: 0px;
    }
    &__col {
      color: $black;
      text-transform: uppercase;
    }
    &__col > p {
      margin-top: 50px;
      font-size: 20px;
      text-transform: initial;
      color: $gray;
      line-height: 1.2;
      @include phone {
        font-size: 16px;
        line-height: 18.48px;
        margin: 10px 0;
      }
    }
    &__col > hr {
      background: $black;
      animation: hr-in 1s;
    }
    &__col:first-child {
      padding-top: 90%;
      text-align: right;
      p {
        animation: first-paragraph-in 1s;
      }
      @include phone {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-top: 50px;
        height: 100%;
        background-image: url('../../assets/images/bg-mobile-1.png');
        background-size: cover;
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
      }
      h1 {
        @include phone {
          text-align: left;
          color: #fff;
        }
      }
    }
    &__col.images {
      position: relative;
      height: 750px;
      img {
        position: absolute;
      }
      img:nth-of-type(1) {
        z-index: 5;
        bottom: 0px;
        left: 70px;
        width: 330px;
        border-radius: 10px;
        animation: girl-in 1s;
        transition: .2s;
      }
      div.girl-border {
        position: absolute;
        width: 345px;
        height: 480px;
        border: 1px solid $black;
        bottom: 0px;
        left: 70px;
        z-index: 2;
        animation: girl-border-in 1s;
        transition: .2s;
      }
      img:nth-of-type(2) {
        z-index: 4;
        top: 10px;
        left: 15px;
        width: 260px;
        border-radius: 10px;
        animation: boy-in 1s;
        transition: .2s;
      }
      div.boy-border {
        width: 282px;
        height: 515px;
        border: 1px solid $black;
        position: absolute;
        top: 0px;
        left: 2px;
        z-index: 3;
        animation: boy-border-in 1s;
      }
      img:nth-of-type(3) {
        z-index: 1;
        bottom: 50px;
        right: 10px;
        width: 280px;
        animation: bg-in 1s;
        transition: .2s;
      }
      img:nth-of-type(4) {
        z-index: 1;
        bottom: 0px;
        left: 23px;
        width: 280px;
        animation: bg-2-in 1s;
      }
      img:nth-of-type(5) {
        z-index: 2;
        bottom: 200px;
        right: -10px;
        width: 65px;
        animation: arc-in 1s;
      }
    }
    &__col:nth-child(2) {
      @include phone {
        display: none;
      }
    }
    &__col:last-child {
      padding-top: 90%;
      text-align: left;
      p {
        animation: second-paragraph-in 1s;
      }
      @include phone {
        padding-top: 25%;
        height: 100%;
        background-image: url('../../assets/images/bg-mobile-2.png');
        background-size: cover;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &__col:first-child > h1 {
      animation: titles-in 1s;
      span {
        font-size: 38px;
        padding-bottom: 5px;
        padding-left: 50px;
        a {
          text-decoration: none;
          color: $black;
          position: relative;
          img {
            position: absolute;
            width: 200px;
            right: 1px;
            top: 3px;
            color: transparent;
            opacity: 0;
          }
          &:hover img {
            opacity: 1;
          }
          @include phone {
            color: #fff;
          }
        }
        @include phone {
          font-size: 29px;
          padding: 0;
          border-bottom-color: #fff;
        }
      }
    }
    &__col:last-child > h1 {
      animation: titles-in 1s;
      span {
        font-size: 38px;
        padding-bottom: 5px;
        padding-right: 50px;
        a {
          text-decoration: none;
          color: $black;
          position: relative;
          img {
            position: absolute;
            width: 155px;
            left: 4px;
            top: 4px;
            color: transparent;
            opacity: 0;
          }
          &:hover img {
            opacity: 1;
          }
          @include phone {
            color: #fff;
          }
        }
        @include phone {
          font-size: 29px;
          padding: 0;
          border-bottom-color: #fff;
        }
      }
    }
  }
  &__footer {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 70px;
    @include phone {
      display: none;
    }
    &__text {
      display: flex;
      align-items: center;
      padding-left: 20px;
      animation: onfire-in 1s;
      text-decoration: none;
      p {
        opacity: 0.35;
        color: $black;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
      }
      img {
        margin-left: 5px;
        height: 24px;
        opacity: 0.35;
        transition: opacity .2s;
        &:hover {
          opacity: 1;
        }
      }
    }
    &__links {
      width: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      animation: links-in 1s;
      a {
        opacity: 0.35;
      }
      a:nth-of-type(1):hover {
        opacity: 1;
        filter: invert(56%) sepia(61%) saturate(1683%) hue-rotate(325deg) brightness(88%) contrast(84%);
      }
      a:nth-of-type(2):hover {
        opacity: 1;
        filter: invert(37%) sepia(12%) saturate(2651%) hue-rotate(190deg) brightness(95%) contrast(82%);
      }
      a:nth-of-type(3):hover {
        opacity: 1;
        filter: invert(82%) sepia(99%) saturate(343%) hue-rotate(356deg) brightness(94%) contrast(77%);
      }
      img {
        height: 16px;
      }
    }
  }
}

.commu-header-intro-mobile {
  display: none;
  @include phone {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    text-align: center;
    margin: 25px 0;
  }
}

@keyframes logo-in {
  from {
    transform: translate(-50px, -50px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes button-in {
  from {
    transform: translate(50px, -50px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes onfire-in {
  from {
    transform: translate(-50px, 50px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes links-in {
  from {
    transform: translate(50px, 50px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes titles-in {
  from {
    transform: translate(0px, -100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes hr-in {
  from {
    transform: translate(0px, 250px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes first-paragraph-in {
  from {
    transform: translate(-150px, 0px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes second-paragraph-in {
  from {
    transform: translate(150px, 0px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes boy-in {
  from {
    transform: translate(-500px, 0px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes boy-border-in {
  from {
    transform: translate(-250px, -100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes girl-in {
  from {
    transform: translate(-400px, -50px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes girl-border-in {
  from {
    transform: translate(100px, 100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes bg-in {
  from {
    transform: translate(500px, -100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes bg-2-in {
  from {
    transform: translate(100px, 100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes arc-in {
  from {
    transform: translate(500px, 0px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes rotate {
  0% {
    top: 0;
    left: 8%;
  }
  25% {
    top: 8%;
    left: 0%;
  }
  50% {
    top: 16%;
    left: 8%;
  }
  75% {
    top: 8%;
    left: 16%;
  }
  100% {
    top: 0;
    left: 8%;
  }
}


@keyframes move {
  0% {
    transform: translate(0, 10px);
  }
  25% {
    transform: translate(10px, 10px);
  }
  50% {
    transform: translate(10px, 0);
  }
  75% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(-10px, -10px);
  }
}