$smartphone-width: 320px;
$mobile-width: 480px;
$tablet-width: 768px;
$laptop-width: 1366px;
$desktop-width: 1024px;

@mixin phone {
  @media (min-width: #{$smartphone-width}) and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin landscape {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width + 1px}) and (max-width: 1280px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-width + 1px}) and (max-width: 1280px) {
    @content;
  }
}

@mixin lgdesktop {
  @media (min-width: 1281px) {
    @content;
  }
}
