@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-breadcrumb {
  padding: 20px 0;
  font-family: 'Bicyclette-Regular';
  font-size: 20px;
  color: $gray;
  i {
    @include phone {
      padding-left: 5px;
    }
  }
  span {
    padding-left: 10px;
    @include phone {
      padding-left: 5px;
    }
  }
  @include phone {
    font-size: 14px;
    padding: 40px 0 0 0;
  }

}