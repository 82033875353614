@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-footer {
  background: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: auto;
  &.black {
    background: $black;
    .commu-footer__wrapper {
      &__pages{
        span {
          color: #D0D0D0
        }
        span.active {
          color: #fff;
          &::after {
            background: #fff;
          }
        }
      }
      &__buttons {
        border-color: #fff;
        button {
          outline: none;
          &:first-of-type {
            border-right-color: #fff;
          }
        }
      }
      &__links {
        width: 220px;
        span {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  &__wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 100px 1fr;
    align-items: center;
    @include phone {
      grid-template-columns: 100%;
      justify-items: center;
    }
    &__pages {
      span {
        font-size: 14px;
        padding-right: 3px;
        padding-left: 3px;
      }
      span.active {
        color: #000;
        font-size: 20px;
        padding-right: 54px;
        position: relative;
        &::after {
          content: "";
          width: 50px;
          height: 1px;
          background: #000;
          position: absolute;
          right: 2px;
          top: 10px;
        }
      }
      @include phone {
        display: none;
      }
    }
    &__buttons {
      width: 100px;
      border: 1px solid #070809;
      border-radius: 18px;
      height: 35px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      button {
        cursor: pointer;
        width: 50px;
        border: 0;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        &:first-of-type {
          border-right: 1px solid #070809;
        }
      }
    }
    &__links {
      justify-self: end;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100px;
      a {
        opacity: 0.35;
        img {
          height: 16px;
        }
      }
      a:nth-of-type(1):hover {
        opacity: 1;
        filter: invert(56%) sepia(61%) saturate(1683%) hue-rotate(325deg) brightness(88%) contrast(84%);
      }
      a:nth-of-type(2):hover {
        opacity: 1;
        filter: invert(37%) sepia(12%) saturate(2651%) hue-rotate(190deg) brightness(95%) contrast(82%);
      }
      a:nth-of-type(3):hover {
        opacity: 1;
        filter: invert(82%) sepia(99%) saturate(343%) hue-rotate(356deg) brightness(94%) contrast(77%);
      }
      @include phone {
        display: none;
      }
    }
  }
}