@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-main-marketplace {
  width: 100%;
  min-height: 100vh;
  background: $black;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include phone {
    height: 100%;
  }

  &__header {
    margin: 0 auto;
    text-align: center;
    padding: 55px 0 0;
    svg {
      width: 245px;
      height: auto;
    }
    @include phone {
      padding: 40px 0;
      svg {
        width: 215px;
      }
    }
  }
  &__wrapper {
    max-width: 1000px;
    margin: 0 auto -75px;
    display: grid;
    grid-template-columns: 1fr 410px;
    align-items: flex-start;
    @include phone {
      grid-template-columns: 100%;
      padding: 20px;
      margin-bottom: auto;
    }
    &__textfield {
      display: flex;
      align-items: center;
      width: 325px;
      height: 44px;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 5px 12px;
      box-sizing: border-box;
      img {
        height: 14px;
      }
      input {
        flex: 1;
        margin: 0 10px;
        background: transparent;
        border: none;
        color: #fff;
        outline: none;
        &::placeholder {
          color: #fff
        }
      }
      button {
        background: transparent;
        color: #fff;
        border: none;
        cursor: pointer;
      }
    }
  }
  &__wrapper > div:first-child {
    padding-top: 115px;
    @include phone {
      padding: 0 16px;
    }
  }
  &__wrapper > div:first-child > h1 {
    font-family: 'Bicyclette-Black';
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 20px;
  }
  &__wrapper > div:first-child > h2 {
    font-family: 'Bicyclette-Black';
    font-size: 44px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 24px;
    @include phone {
      font-size: 26px;
    }
  }
  &__wrapper > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    @include phone {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  &__footer {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 70px;
    @include phone {
      height: auto;
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px;
    }
    &__text {
      display: flex;
      align-items: center;
      padding-left: 20px;
      @include phone {
        order: 2;
        padding-top: 40px;
        padding-bottom: 40px;
      }
      p {
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
      }
      img {
        margin-left: 10px;
        height: 24px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      @include phone {
        order: 3;
      }
      p {
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &__links {
      width: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone {
        order: 1
      }
      img {
        height: 16px;
      }
    }
  }
}