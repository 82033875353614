@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.commu-button-marketplace {
  font-size: 13px;
  font-family: 'Bicyclette-Regular';
  background: $black;
  padding: 12px 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @include phone {
    max-width: 80%;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    bottom: 20px;
    left: 23%;
  }
}
.commu-button-marketplace:hover,
.commu-button-marketplace:focus {
  text-decoration: none;
}